import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fundings: JSON.parse(localStorage.getItem('fundings')) || [],
    fundingBalance: localStorage.getItem('funding-balance') || 0,
    subscriptionBalance: localStorage.getItem('subscriptionBalance') || 0,
    referralBalance: localStorage.getItem('referralBalance') || 0,
    withdrawableBalance: localStorage.getItem('withdrawableBalance') || 0,
    loanBalance: localStorage.getItem('loanBalance') || 0

    
}

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setFundings: (state, action) =>{
            state.fundings = action.payload;  
            localStorage.setItem('fundings', JSON.stringify(state.fundings));
        },
        setFundingBal: (state, action) =>{
            state.fundingBalance = action.payload;  
            localStorage.setItem('funding-balance', state.fundingBalance);
        },
        setSubBalance: (state, action) =>{
            state.subscriptionBalance = action.payload;  
            localStorage.setItem('subscriptionBalance', state.subscriptionBalance);
        },
        
        setReferralBalance: (state, action) =>{
            state.referralBalance = action.payload;  
            localStorage.setItem('referralBalance', state.referralBalance);
        } ,
        setWithdrawableBalance: (state, action) =>{
            state.withdrawableBalance = action.payload;  
            localStorage.setItem('withdrawableBalance', state.withdrawableBalance);
        },

        setLoanBalance: (state, action) =>{
            state.loanBalance = action.payload;  
            localStorage.setItem('loanBalance', state.loanBalance);
        }
    }
})

export const {setFundings, setFundingBal, setLoanBalance, setSubBalance, setReferralBalance, setWithdrawableBalance} = walletSlice.actions;
export default walletSlice.reducer;