import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TransactionList from './TransactionList';
import { Link as RouterLink } from 'react-router-dom';

import WalletSlide from './WalletSlide';
import WalletButton from './WalletButton';
import CoinsPayment from './CoinsPayment';
import Markets from '../Home/Markets';
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
const Wallet = () => {
  
  return (
    <div style={{marginTop: '75px'}}>
        {/* <WalletSlide/> */}
        <Container>
          <Card elevation={1}  mx={0} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography my={0} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
              Connect your wallet or select any coin below to fund your wallet
              </Typography>
              </CardContent>  
              <CardActions sx={{justifyContent: 'space-between'}}>

              <WalletButton/>        

              <WithdrawalButton/>

              </CardActions>
      
            </Box>
            
          </Card>
          <Markets/>
        {/* <TransactionList/> */}

        </Container>
    </div>
  )
}

export default Wallet
