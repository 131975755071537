import React, {useState, Fragment, useEffect} from 'react'
import { useMutation } from "react-query";

import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CardContent } from '@mui/material';


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import apiClient from "../../request/http-common";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
const EditTransfer = () => {

    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const loanBalance = useSelector((state) => state.wallet.loanBalance);
    const selectedtransfer = useSelector((state) => state.selectedTrasfer.selectedTransfer);

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);

    const initialTransfer = {
        amount: selectedtransfer.amount,
        accountNumber: selectedtransfer.account_number,
        transferType: selectedtransfer.transfer_type,
        interAccountNumber: selectedtransfer.interAccountNumber,
        receiverEmail: selectedtransfer.receiverEmail,
        bankName: selectedtransfer.bankName,
        swiftCode: selectedtransfer.swiftCode,
        routineNumber: selectedtransfer.routineNumber,
        country: selectedtransfer.country,
        description: selectedtransfer.description,
        transfer_date: selectedtransfer.created_at,
        source: selectedtransfer.source,
        status: selectedtransfer.status,
    }
    const [error, setErros] = useState('');
    const [receiver, setReceiver] = useState(null);
    const [countries, setCountries] = useState([]);
    const [transferForm, setTransferForm] = useState(initialTransfer);
    const onChange = (e) =>
    setTransferForm({ ...transferForm, [e.target.name]: e.target.value });


const onKeyUp = (e) => {
  let number = e.target.value;
  let count = number.toString().length;
  if (count === 10) {
    showDetails();
  }
}
    const { isLoading: isSendingRequest, mutate: postTransfer } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/admin-update-transfer`, {
            transfer_id: selectedtransfer.id,
            amount: transferForm.amount,
            account_number: transferForm.accountNumber,
            transfer_type: transferForm.transferType,
            interAccountNumber : transferForm.interAccountNumber,
            receiverEmail : transferForm.receiverEmail,
            bankName : transferForm.bankName,
            swiftCode : transferForm.swiftCode,
            routineNumber : transferForm.routineNumber,
            country : transferForm.country,
            description : transferForm.description,
            transfer_date: transferForm.transfer_date,
            source: transferForm.source,
            status : transferForm.status,
                       
          });
        },
        {
            onSuccess: (res) => {

                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
                setTimeout(() => {
                    navigate('/transfers');
                }, 2000);
                
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }    
              },
        }
      );


      const { isLoading: isFetchingDetails, mutate: showDetails } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/transfer-details/${transferForm.accountNumber}`);
        },
        {
            onSuccess: (res) => {
                setReceiver(res.data.user);
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }   
              },
        }
      );
    


      const { isLoading: isFetchingCountry, mutate: loadCountry } = useMutation(        
        async () => {
          return await apiClient.get(`/api/countries`);
        },
        {
            onSuccess: (res) => {
                setCountries(res.data.countries);
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }   
              },
        }
      );

      useEffect(() => {
        loadCountry()
      }, []);
      const { transferType } = transferForm;
  return (
        <div style={{marginTop: '70px'}}>
            {selectedtransfer !== null && 

        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '0px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                    EDIT TRANSFER
                    </Typography>

                    
                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="transferType">Transfer Type</InputLabel>
                            {(error !== '' && error.transferType) ?
                                <Select
                                    
                                    error
                                    onChange={onChange}
                                    labelId="transferType"
                                    id="transferType"
                                    name="transferType"
                                    defaultValue={selectedtransfer.transfer_type}
                                    
                                    label={`Transfer Type`}
                                    helperText={error.transferType}
                                    >
                                    <MenuItem selected value=''>
                                        Transfer Type
                                    </MenuItem> 
                                    <MenuItem selected value="Interbank Transfer">
                                    INTERBANK TRANSFER
                                    </MenuItem>

                                    <MenuItem selected value="Intrabank Transfer">
                                    UPTHRUST PAY TRANSFER
                                    </MenuItem>           
                                    
                                </Select>
                            :
                                <Select
                                    onChange={onChange}
                                    labelId="transferType"
                                    name="transferType"
                                    defaultValue={selectedtransfer.transfer_type}
                                    
                                    id="transferType"
                                    label={`Transfer Type`}
                                    >
                                    <MenuItem selected value="">
                                        Transfer Type
                                    </MenuItem>   

                                    <MenuItem selected value="Interbank Transfer">
                                    INTERBANK TRANSFER
                                    </MenuItem>

                                    <MenuItem selected value="Intrabank Transfer">
                                    UPTHRUST PAY TRANSFER
                                    </MenuItem>   
                                </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.transferType}
                            </Typography>
                        </FormControl>

                        
                    {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="source">source</InputLabel>
                            {(error !== '' && error.source) ?
                                <Select
                                    error
                                    onChange={onChange}
                                    labelId="source"
                                    id="source"
                                    name="source"
                                    defaultValue={selectedtransfer.source}
                                    
                                    label={`source`}
                                    helperText={error.source}
                                    >
                                    <MenuItem selected value=''>
                                    Select Source
                                    </MenuItem> 
                                    <MenuItem selected value="Account Balance">
                                        Account Balance (bal = {formatPrice(fundingBal)})
                                    </MenuItem>

                                    <MenuItem selected value="Cash Back">
                                        Cash Back (bal = {formatPrice(subBal)})
                                    </MenuItem>

                                    <MenuItem selected value="Investment Returns">
                                        Investment Returns (bal = {formatPrice(mainBal)})
                                    </MenuItem> 

                                    <MenuItem selected value="Loans">
                                        Loans (bal = {formatPrice(loanBalance)})
                                    </MenuItem>           
                                    
                                </Select>
                            :
                                <Select
                                    onChange={onChange}
                                    labelId="source"
                                    name="source"
                                    defaultValue={selectedtransfer.source}
                                    
                                    id="source"
                                    label={`source`}
                                    >
                                    <MenuItem selected value=''>
                                    Select Source
                                    </MenuItem> 
                                    <MenuItem selected value="Account Balance">
                                        Account Balance (bal = {formatPrice(fundingBal)})
                                    </MenuItem>

                                    <MenuItem selected value="Cash Back">
                                        Cash Back (bal = {formatPrice(subBal)})
                                    </MenuItem>

                                    <MenuItem selected value="Investment Returns">
                                        Investment Returns (bal = {formatPrice(mainBal)})
                                    </MenuItem> 

                                    <MenuItem selected value="Loans">
                                        Loans (bal = {formatPrice(loanBalance)})
                                    </MenuItem> 
                                     
                                </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.source}
                            </Typography>
                        </FormControl> */}

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="amount">Amount</InputLabel>
                            {(error !== '' && error.amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="amount"
                            type={'number'}
                            name="amount"
                            defaultValue={selectedtransfer.amount}
                            
                            label="Amount"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            type={'number'}
                            name="amount"
                            defaultValue={selectedtransfer.amount}
                            
                            label="Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                            
                        </FormControl>

                        {selectedtransfer.transfer_type === 'Intrabank Transfer' && 
                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="accountNumber">Account Number</InputLabel>
                            {(error !== '' && error.accountNumber) ?

                            <OutlinedInput
                            
                            onChange={onChange} onKeyUp={onKeyUp} onPaste={onKeyUp} onKeyDown={onKeyUp} 
                            error
                            id="accountNumber"
                            type={'number'}
                            name="accountNumber"
                            defaultValue={selectedtransfer.account_number}
                            
                            label="Account Number"
                            helperText={error.accountNumber}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange} onKeyUp={onKeyUp} onPaste={onKeyUp} onKeyDown={onKeyUp} 
                            id="accountNumber"
                            type={'number'}
                            name="accountNumber"
                            defaultValue={selectedtransfer.account_number}
                            
                            label="Account Number"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.accountNumber}

                            {isFetchingDetails? 'fetching user details....' : ''}
                            </Typography>
                        </FormControl>

                        }

                        {receiver !== null && 
                         <Alert severity="info">you are about to transfer {formatPrice(transferForm.amount)} to {receiver.name}</Alert>
                        }
                        {selectedtransfer.transfer_type === 'Interbank Transfer' && 
                        <Fragment>
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Account Number">Account Number</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="interAccountNumber"
                            name="interAccountNumber"
                            defaultValue={selectedtransfer.interAccountNumber}
                            
                            type={'text'}
                            label="Account Number"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="interAccountNumber"
                            name="interAccountNumber"
                            defaultValue={selectedtransfer.interAccountNumber}
                            
                            type={'text'}
                            label="Account Number"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="bankName">Bank Name</InputLabel>
                        {(error !== '' && error.bankName) ?

                        <OutlinedInput
                        onChange={onChange}
                        error
                        id="bankName"
                        type={'text'}
                        name="bankName"
                        defaultValue={selectedtransfer.bankName}
                        
                        label="Bank Name"
                        helperText={error.bankName}
                        />
                        :
                        <OutlinedInput
                        onChange={onChange}
                        id="bankName"
                        type={'text'}
                        name="bankName"
                        defaultValue={selectedtransfer.bankName}
                        
                        label="Bank Name"
                        />
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.bankName}
                        </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="receiverEmail">Receiver Email</InputLabel>
                            {(error !== '' && error.receiverEmail) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="receiverEmail"
                            type={'email'}
                            name="receiverEmail"
                            defaultValue={selectedtransfer.receiverEmail}
                            
                            label="Receiver Email"
                            helperText={error.receiverEmail}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="receiverEmail"
                            type={'email'}
                            name="receiverEmail"
                            defaultValue={selectedtransfer.receiverEmail}
                            
                            label="Receiver Email"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.receiverEmail}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="swiftCode">SWIFT code/BIC</InputLabel>
                            {(error !== '' && error.swiftCode) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="swiftCode"
                            type={'text'}
                            name="swiftCode"
                            defaultValue={selectedtransfer.swiftCode}
                            
                            label="SWIFT code/BIC"
                            helperText={error.swiftCode}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="swiftCode"
                            type={'text'}
                            name="swiftCode"
                            defaultValue={selectedtransfer.swiftCode}
                            
                            label="SWIFT code/BIC"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.swiftCode}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="routineNumber">Routing Number</InputLabel>
                            {(error !== '' && error.routineNumber) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="routineNumber"
                            type={'number'}
                            name="routineNumber"
                            defaultValue={selectedtransfer.routineNumber}
                            
                            label="Routing Number"
                            helperText={error.routineNumber}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="routineNumber"
                            type={'number'}
                            name="routineNumber"
                            defaultValue={selectedtransfer.routineNumber}
                            
                            label="Routing Number"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.routineNumber}
                            </Typography>
                            
                        </FormControl>

                        {(countries !== '' && countries.length > 0 )&&
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="country">Select Country</InputLabel>
                        {(error !== '' && error.country) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="country"
                        id="country"
                        name="country"
                        defaultValue={selectedtransfer.country}
                        
                        label={`Select Country`}
                        helperText={error.country}
                        >
                        <MenuItem selected value=''>
                            Select Country
                        </MenuItem> 
                        {countries.map(country =>(
                            <MenuItem value={country.name}>
                                <img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={country.flag} />
                                {country.name} </MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="country"
                            name="country"
                            defaultValue={selectedtransfer.country}
                            
                            id="country"
                            label={`Select Country`}
                            >
                            <MenuItem selected value="">
                                Select Country
                            </MenuItem>              
                            {countries.map(country =>(
                            <MenuItem value={country.name}>
                                <img style={{height: "15px", width: "20px", marginRight: '4px'}} alt="Menubar Icon" src={country.flag} />
                                {country.name} </MenuItem>
                            ))} 
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.country}
                        </Typography>
                    </FormControl>
                        }

                        </Fragment>
                        }  

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="description">Description</InputLabel>
                            {(error !== '' && error.Description) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="description"
                            name="description"
                            defaultValue={selectedtransfer.description}
                            
                            type={'text'}
                            label="description"
                            helperText={error.Description}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="description"
                            name="description"
                            defaultValue={selectedtransfer.description}
                            
                            type={'text'}
                            label="description"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.Description}
                            </Typography>
                        </FormControl>  

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="transfer_date">Transfer Date ({selectedtransfer.created_at})</InputLabel>
                            {(error !== '' && error.transfer_date) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="transfer_date"
                            type={'datetime-local'}
                            name="transfer_date"
                            defaultValue={selectedtransfer.created_at}
                            label={`Transfer Date (${selectedtransfer.created_at})`}
                            helperText={error.transfer_date}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="transfer_date"
                            type={'datetime-local'}
                            name="transfer_date"
                            defaultValue={selectedtransfer.created_at}
                            label={`Transfer Date (${selectedtransfer.created_at})`}
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.transfer_date}
                            </Typography>
                        </FormControl>                    
                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="status">status</InputLabel>
                            <Select
                                onChange={onChange}
                                labelId="status"
                                id="status"
                                name="status"
                                label='status'
                                defaultValue={selectedtransfer.status}
                                >
                                <MenuItem selected value=''>
                                    select status
                                </MenuItem> 
                                <MenuItem value="pending">pending</MenuItem>
                                <MenuItem value="approved">Approved</MenuItem>
                                <MenuItem value="declined">Declined</MenuItem>
                            </Select>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={postTransfer} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'updating...' : 'Edit Transfer'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>
  )
}

export default EditTransfer