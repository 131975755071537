import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTransfer: JSON.parse(localStorage.getItem('selectedTransfer')) || null,
}

export const selectedTransfertSlice = createSlice({
    name: 'selectedTransfer',
    initialState,
    reducers: {
        setselectedTransfer: (state, action) =>{
            state.selectedTransfer = action.payload;  
            localStorage.setItem('selectedTransfer', JSON.stringify(state.selectedTransfer));
        },      
    }
})

export const {setselectedTransfer} = selectedTransfertSlice.actions;
export default selectedTransfertSlice.reducer;