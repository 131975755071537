import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HistoryIcon from '@mui/icons-material/History';

export default function MobileMenu() {
  return (
    <Box sx={{display: {md: 'none'}, paddingX: '15px', marginTop: '75px'}}>
    <Stack mt={2} sx={{ marginTop: '20px'}} direction="row" spacing={5} justifyContent="flex-start" alignItems="center">
      <Box component={RouterLink}  to='/cards' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <CreditCardIcon />
      </Avatar>  
      <span>Cards</span>   
      </Box>

      <Box component={RouterLink} to='/transfers' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <HistoryIcon />
      </Avatar>
      <Typography>History</Typography> 
      </Box>

      <Box component={RouterLink} to='/transfer' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit'  }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <CurrencyExchangeIcon />
      </Avatar>
      <Typography sx={{textAlign: 'center'}}>Transfer</Typography> 
      </Box>

      <Box component={RouterLink} to='/transfer-pin' sx={{ borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar sx={{ padding: '5px', width: 56, height: 56}}>
        <VpnKeyIcon />
      </Avatar>
      <span>Security</span> 
      </Box>      
    </Stack>
    </Box>
  );
}