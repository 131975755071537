import React, {useState, Fragment, useEffect} from 'react'
import { useMutation } from "react-query";

import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CardContent } from '@mui/material';


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import apiClient from "../../request/http-common";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const TransferPin = () => {

    const user = useSelector((state) => state.user.user);

    const initialpin = {
        oldPin: '',
        NewPin: '',
        confirmPin: ''
    }
    const [error, setErros] = useState('');
    const [pinForm, setpinForm] = useState(initialpin);
    const onChange = (e) =>
    setpinForm({ ...pinForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postpin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/update-pin`, {
            old_pin: pinForm.oldPin,
            pin: pinForm.NewPin,
            pin_confirmation: pinForm.confirmPin
          });
        },
        {
            onSuccess: (res) => {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }   
              },
        }
      );

  return (
    <Container> 
    <div style={{marginTop: '70px'}}>
    <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} md={12}>
            <Card sx={{marginX: '0px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                UPDATE TRANSFER PIN
                </Typography>
                <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    Fill the form below to update pin
                </Typography>

                    {user.transfer_pin !== null &&
                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="oldPin">Enter Old Transfer Pin</InputLabel>
                    {(error !== '' && error.oldPin) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    id="oldPin"
                    type={'number'}
                    name="oldPin"
                    label="Enter Old Transfer Pin"
                    helperText={error.oldPin}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="oldPin"
                    type={'number'}
                    name="oldPin"
                    label="Enter Old Transfer Pin"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.oldPin}
                    </Typography>
                    
                </FormControl> 
                    }
                                       
                    
                                       <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="NewPin">Enter New Transfer Pin</InputLabel>
                    {(error !== '' && error.NewPin) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    id="NewPin"
                    type={'number'}
                    name="NewPin"
                    label="Enter New Transfer Pin"
                    helperText={error.NewPin}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="NewPin"
                    type={'number'}
                    name="NewPin"
                    label="Enter New Transfer Pin"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.NewPin}
                    </Typography>
                    
                </FormControl> 


                <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                    <InputLabel htmlFor="confirmPin">Confirm Transfer Pin</InputLabel>
                    {(error !== '' && error.confirmPin) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    id="confirmPin"
                    type={'number'}
                    name="confirmPin"
                    label="Confirm Transfer Pin"
                    helperText={error.confirmPin}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="confirmPin"
                    type={'number'}
                    name="confirmPin"
                    label="Confirm Transfer Pin"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.confirmPin}
                    </Typography>
                    
                </FormControl> 
                </CardContent>   

                <CardActions>
                    <Button onClick={postpin} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'updating...' : 'Update Pin'}</Button>
                </CardActions>
            </Card>
        </Grid>
    </Grid>
</div>
</Container>
  )
}

export default TransferPin